<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-card-text class="pt-0">
        <v-form ref="form_otro">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field v-model="form_otro.correo" :rules="[rules.requerido, rules.email]" :color="colores.primario" label="CORREO *"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea v-model="form_otro.descripcion" :rules="[rules.requerido]" :color="colores.primario" label="DESCRIPCIÓN *"></v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="text-left">
                <uploadFile :session="session" :keyform="form_otro.keyform" :items_archivos="form_otro.items_archivos" :urlroute="'upload_cargar_otro'" :tipo="'LOG OTRO'" :colores="colores" @refresh_archivos="refrescar_archivos"></uploadFile>
              </v-col>
            </v-row>
            <br>
            <v-row no-gutters v-if="session.area !== 17">
              <v-col cols="12" class="text-center">
                <v-btn @click="validar" block large :color="colores.black" dark>GUARDAR</v-btn>
              </v-col>
            </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-sheet>
  <br><br>
  <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
</div>
</template>

<script>
import recGestiones from '@/js/rec_gestiones.js'
import recFunciones from '@/js/rec_funciones.js'
// import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
// import uploadFile from '@/components/widgets/uploadComponent.vue'
export default {
  name: 'form_qr_cierreComponent',
  components: {
    uploadFile: () => import('@/components/widgets/uploadComponent.vue'),
    mensajesSave: () => import('@/components/widgets/mensajes_saveComponent.vue')
  },
  mixins: [recFunciones, recGestiones],
  props: ['colores', 'rules', 'gestion_id', 'session', 'form_otro', 'codigo'],
  data: () => ({
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null },
    items_errores: []
  }),
  methods: {
    validar () {
      if (this.$refs.form_otro.validate()) {
        this.dialogo.pregunta = true
      } else {
        this.dialogo.incompleto = true
      }
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_gestion_cierre_otro()
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
      } else if (newvalue === 'DETALLES') {
        this.dialogo.detalle = false
      }
    },
    refrescar_archivos (data) {
      for (let i = 0; i < data.length; i++) {
        this.form_otro.items_archivos.push(data[i])
      }
    }
  }
}
</script>
